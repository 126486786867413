<template>
      <div class="container-fluid">
        <div class="row">

            <sidebar></sidebar>
            <!-- MAIN CONTENT start -->
            <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10 h-100 pb-5">
                <div class="row">
                    <div class="col-12 mb-5">
                        <app-header></app-header>
                        <page-header :page-title="pageData.title" :breadcrumb="pageData.breadcrumb"></page-header>
                    </div>
                </div>

                <!-- Club List -->
                <section class=" px-0 mx-0 px-xl-5 mx-xl-5 mb-3">
<!--                    <div class="row mb-5 pb-3">-->
<!--                        <div class="col-12">-->
<!--                            <h3 class="text-center mb-4">{{$t('clubs.amount-spent-by-year')}}</h3>-->
<!--                            <div class="bg-light p-5 b-30 shadow-1" >-->
<!--                                <table class="table-blue-wide w-100">-->
<!--                                    <thead>-->
<!--                                        <tr>-->
<!--                                            <th>{{$t('clubs.year')}}</th>-->
<!--                                            <th>{{$t('clubs.number-of-projects')}}</th>-->
<!--                                            <th>{{$t('clubs.number-of-students')}}</th>-->
<!--                                            <th>{{$t('clubs.amount')}}</th>-->
<!--                                        </tr>-->
<!--                                    </thead>-->
<!--                                    <tbody>-->
<!--                                        <tr>-->
<!--                                            <td :data-label="$t('clubs.year')">2012</td>-->
<!--                                            <td :data-label="$t('clubs.number-of-projects')"> 12</td>-->
<!--                                            <td :data-label="$t('clubs.students')">456</td>-->
<!--                                            <td :data-label="$t('clubs.amount')">414 GEL</td>-->
<!--                                        </tr>-->
<!--                                    </tbody>-->
<!--                                </table>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->

                  <router-view></router-view>
                </section>


            </main>
        </div>
    </div>
</template>
<script>

import sidebar from '../components/navbar/sidebar';
import appHeader from '../components/app-header';
import pageHeader from '../components/page/page-header';


export default {
    name:'clubs',
    components: { sidebar, appHeader, pageHeader },
    computed: {
        pageData() {
        return {
            title: this.$t('clubs.title'),
            breadcrumb: [{
            icon: '',
            title: this.$t('home.title'),
            link: '/',
            isActive: false,
            },
            {
            icon: '',
            title: this.$t('clubs.title'),
            link: '/clubs',
            isActive: true,
            }],
        };
        },
    },
}
</script>
